import axios from 'axios'
import React, { useState } from 'react'
import Footer from '../Core/Footer'
import NavigationBar from '../Core/Navbar'
import { productData } from '../Products/productDetails';
import { Modal, Button } from "react-bootstrap";
import './CatalogueDetails.css'
import { init } from 'emailjs-com';
import emailjs from 'emailjs-com';

const CatalogueDetails = () => {

    var id = localStorage.getItem("productID")
    const [value, setValue] = useState({
        isOpen: false
    });

    const { isOpen } = value;

    const [values, setValues] = useState({
        name: "",
        companyName: "",
        contactNumber: "",
        email: "",
        productName: productData[id].name,

    });

    const closeModal = () => {
        setValue({ isOpen: false })
        setValues({ err: false })
    }

    const { name, companyName, contactNumber, email, productName } = values;

    const handleChange = name => event => {

        setValues({ ...values, [name]: event.target.value })
    }

    const onSubmit = event => {
        event.preventDefault();

        axios.post(process.env.REACT_APP_CATALOGUE_ENQUIRY_SPREADSHEET_LINK, {
            name, companyName, contactNumber, email, productName
        }).then
            (response => {

                init(process.env.REACT_APP_USER_ID);
                emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID_CATALOGUE, event.target, process.env.REACT_APP_USER_ID)
                    .then((result) => {

                    }, (error) => {
                        setValue({ isOpen: false })
                    });
                setValue({ isOpen: true });
                setValues({
                    name: "",
                    companyName: "",
                    contactNumber: "",
                    email: "",
                    productName: productData[id].name,
                    err: false
                })
            }

            )
            .catch(setValue({ isOpen: false }), setValues({ ...values, err: true }))

    }

    return (
        <div>
            <NavigationBar />

            <Modal show={isOpen} onHide={closeModal} className="modal__description">
                <Modal.Header closeButton>
                    <Modal.Title>Thank You User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-background-color">
                        <span className="modal__description">Thank You Dear User, you will soon receive a mail from our Team</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="success" onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>


            <center> <div className="col-xl-4 col-md-4 col-lg-4 justify-content-center">
                <form onSubmit={onSubmit}>
                    <h1 className="catalogue_title">Catalogue Enquiry</h1>
                    <div className="form-group" id="label">
                        <label className="text-dark" id="label">Name </label>
                        <input required value={name || ''} onChange={handleChange("name")} className="form-control" type="text" ></input>
                    </div>
                    <div className="form-group" id="label">
                        <label className="text-dark" id="label">Company Name</label>
                        <input value={companyName || ''} onChange={handleChange("companyName")} className="form-control" type="text"></input>
                    </div>
                    <div className="form-group" id="label">
                        <label className="text-dark" id="label"> Contact Number </label>
                        <input required value={contactNumber || ''} onChange={handleChange("contactNumber")} className="form-control" type="text"></input>
                    </div>
                    <div className="form-group" id="label">
                        <label className="text-dark" id="label"> Email </label>
                        <input required value={email || ''} onChange={handleChange("email")} className="form-control" type="text"></input>
                    </div>


                    <div className="form-group" id="label" >
                        <input type="submit" className="btn btn-dark btn-block" id="userSignUpButton" />
                        <br />

                    </div>
                </form>
            </div>
            </center>
            <Footer />
        </div>
    )
}

export default CatalogueDetails
