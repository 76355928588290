import React from 'react';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faYoutube, faTwitter} from '@fortawesome/free-brands-svg-icons';
import { faHome, faInfoCircle,  faHandshake, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';
import logo from '../../Assets/Images/Logo/footerLogo.png'

import './Footer.css';

const Footer = () => {

    const getProductID = (id) => {
        localStorage.setItem("productID", id)
    }

    return (

        <div className="footerBackground">
            <Fade> <br /> <br />
                <div className="row">
                    <div className="col-xl-4 col-lg-4" id="footerTitle">
                        <center> <span className="logo"><img src={logo} className="footerLogo" alt="simtek medico systems. we provide the best syringe pump" /></span> </center>
                        <br />
                        <h5 className="address">
                            <FontAwesomeIcon icon={faMapMarkerAlt} /> : Simtek Medico Systems Pvt. Ltd. 110, Bhola Bhagwan Ind Estate, I B Patel Road, Goregaon (E), Mumbai- 400063. INDIA
                        </h5>
                        <br />
                        <h5 className="telephone" >
                            <FontAwesomeIcon icon={faPhoneAlt} /> :
                            
                            <a href="tel:+912249733568" className="telephone__number">+91 22 4973 3568</a> /
                            <a href="tel:+912242642465" className="telephone__number">+91 22 4264 2465</a>
                        
                        </h5>
                        <br />
                        <h5 className="telephone" >
                             <FontAwesomeIcon icon={faPhoneAlt} /> : 
                             
                            <a href="tel:+919821340982" className="telephone__number">+91 9821340982 </a> /
                            <a href="tel:+917977099618" className="telephone__number">+91 7977099618 </a> /
                            <a href="tel:+919930740982" className="telephone__number">+91 9930740982 </a>
                        </h5>
                        <br/>
                        <h5 className="mail">
                            <FontAwesomeIcon icon={faEnvelope} /> :

                            <a href="mailto:simtekm@yahoo.co.in" className="telephone__number"> simtekm@yahoo.co.in</a> /
                            <a href="mailto:simtekmedicosystem@gmail.com" className="telephone__number"> simtekmedicosystem@gmail.com</a>
                        </h5>
                        <br />
                        <h5 className="socialMedia" >Follow us :
                            <a href="https://www.instagram.com/simtek_medico/" className="socialMediaLinks"><FontAwesomeIcon icon={faInstagram} /> </a>
                            <a href="https://www.facebook.com/simtekmedicosystems/" className="socialMediaLinks"><FontAwesomeIcon icon={faFacebook} /> </a>
                            <a href="https://twitter.com/simtekm" className="socialMediaLinks"><FontAwesomeIcon icon={faTwitter} /> </a>
                            <a href="https://www.youtube.com/channel/UCKozNYrwQOEWRmbXj_vse1A" className="socialMediaLinks"><FontAwesomeIcon icon={faYoutube} /> </a>

                        </h5>


                    </div>
                    <div className="col-xl-3  col-lg-3 offset-lg-1" id="linkSection">

                        <h1 id="linkSectionTitle">Links</h1> <br />
                        <Link to="/" className="footer-link" > <FontAwesomeIcon className="nav-icon" icon={faHome} />  <span className="footerLink-text">  Home</span></Link> <br />  <br />
                        <Link to="/AboutUs" className="footer-link" > <FontAwesomeIcon className="nav-icon" icon={faInfoCircle} /> <span className="footerLink-text">  About Us</span></Link> <br /> <br />
                        <Link to="/Products" className="footer-link" > <FontAwesomeIcon className="nav-icon" icon={faBoxOpen} />  <span className="footerLink-text">Products</span></Link> <br /> <br />

                        <Link to="/ContactUs" className="footer-link" > <FontAwesomeIcon className="nav-icon" icon={faHandshake} /> <span className="footerLink-text"> Contact</span></Link> <br /> <br />
                        
                    </div>
                    <div className="col-xl-3  col-lg-3 offset-lg-1" id="footerTitle">
                        <h1>Products</h1> <br />
                        <Link to="/ProductDetails" className="product__name"> <h2 className="product__name" onClick={() => getProductID(3)}>INFUTEK I9</h2></Link>  <br />
                        <Link to="/ProductDetails" className="product__name"> <h2 className="product__name" onClick={() => getProductID(0)}>INFUTEK 405</h2> </Link> <br />
                        <Link to="/ProductDetails" className="product__name"> <h2 className="product__name" onClick={() => getProductID(5)}>INFUTEK 405 Ex</h2> </Link> <br />
                        <Link to="/ProductDetails" className="product__name"> <h2 className="product__name" onClick={() => getProductID(4)}>MCARE K</h2> </Link> <br />
                        <Link to="/ProductDetails" className="product__name"> <h2 className="product__name" onClick={() => getProductID(2)}>INFUTEK 500 </h2> </Link> <br />
                        <Link to="/ProductDetails" className="product__name"> <h2 className="product__name" onClick={() => getProductID(1)}>INFUTEK 500 NEW</h2> </Link> <br />

                        
                    </div>
                </div>

                <div className="copyright_div">

                    <h6 className="copyright">© 2017 Simtek Medico Systems Pvt. Ltd. All Rights Reserved.  </h6>
                </div>
                <br />
            </Fade>

        </div>


    )
}

export default Footer
