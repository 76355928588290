import React from 'react'

const ContactUs2 = () => {
    return (
        <div>
            <h3>Hello Simtek</h3>
        </div>
    )
}

export default ContactUs2;
