import React, { Fragment } from 'react';
import Footer from '../Core/Footer';
import NavigationBar from '../Core/Navbar';
import './AboutUs.css';
import Fade from 'react-reveal/Fade';
import vision from '../../Assets/Images/About/vision.png'
import coreValues from '../../Assets/Images/About/coreValues.jpg'
import mission from '../../Assets/Images/About/mission.jpg'
import aboutUs from '../../Assets/Images/About/aboutUs.jpg'

const AboutUs = () => {
    window.scrollTo(0, 0);
    return (
        <Fragment>
            <NavigationBar />
            <div id="about__main__div">

                <Fade>
                    <div className="row" id="about__main__div">
                        <div className="col-xl-6 col-lg-6 col-md-6 about__div">
                            <img src={aboutUs} alt="simtek medico" className="about__image" />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 about__div">
                            <Fade> <br />
                                <h1 className="content__title">About Us </h1>

                                <p className="description"> The new millennium has only served to increase the pace of activities in the Medical Equipments Industry in India and that is how Simtek Medico Systems Pvt. Ltd. was started and is in operation since 1994 to cater to the growing need for specialized and quality medical equipment.</p>
                                <p className="description">The Directors of the organization are biomedical and electronic engineers by profession and had been holding key positions in the R&D division with the world leader companies like TATAs & Siemens Ltd., Germany.</p>
                                <p className="description">Our in-house Research & Development program, can cater to the demand of latest sophisticated Medical Instruments. Our whole range of equipments is updated with the latest technology and they are very sophisticated to meet the need of today's highly technical world of medical science.</p>
                            </Fade> <br />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <br />
                            <img src={vision} alt="simtek medico" className="image" />

                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 vision__div">
                            <Fade><br />
                                <h1 className="content__title">Our Vision </h1>
                                <h1 className="content__subtitle">Life Care... Made Simple. </h1> <br />
                                <p className="description">The vision of Simtek Medico systems is Life Care…. Made Simple. This vision comes into reality by following its 3 main components i.e. Technology, Process and Care with Customer Orientation forming its core. Being in an industry where the primary objective is caring for life, Simtek has designed its every activity to care and concern for its end-user.</p>
                                <br />
                                <p className="description">Life Care…. Made Simple is not just a function; it is instead a way of living and a way of thinking for Simtek as it aspires to cater to the need of every life on the planet.</p>
                                <br /> </Fade>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 coreValues__div">
                            <Fade>
                                <img src={coreValues} alt="simtek medico" className="coreValues__image" />
                            </Fade>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 coreValues__div" >
                            <Fade>
                                <br />
                                <h1 className="content__title">Core Values</h1>

                                <p className="description">The core Values of Simtek Medico Systems enables it to become a trustworthy organization for its customers with Customer Orientation forming its roots and its apex as GIVE TO GET.</p>
                                <ul className="description" id="description">
                                    <li className="description">Give to Get</li>
                                    <li className="description">Customer Orientation</li>
                                    <li className="description">Trust</li>
                                    <li className="description">Technology</li>
                                    <li className="description">Responsibility & Initiative</li>
                                </ul>
                            </Fade>
                            <br /> <br />
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 mission__div">
                            <Fade> <img src={mission} alt="simtek medico" className="coreValues__image" />  </Fade>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 mission__div">
                            <Fade> <br />
                                <h1 className="content__title">Our Mission</h1>

                                <p className="description" >The Mission of Simtek Medico Systems is to focus all its RESOURCES in order to empower our customers TO MAKE THE RIGHT CHOICE. In order to empower our customers to make the right choice we intend to focus on</p>

                                <ul className="description" id="description">
                                    <li className="description" id="description">Training of Human Resource</li>
                                    <li className="description" id="description">Integrating available technologies</li>
                                    <li className="description" id="description">Education & Awareness Programs</li>
                                    <li className="description" id="description">Marketing Initiatives</li>
                                    <li className="description" id="description">Ongoing Research & Development</li>
                                </ul>
                            </Fade>
                            <br />  <br />
                        </div>
                    </div>
                </Fade>
            </div>
            <Footer />
        </Fragment>
    )
}

export default AboutUs
