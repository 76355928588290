import React from 'react';
import HomePage from './Components/Core/HomePage';
import ContactUs from './Components/Forms/ContactUs.js';
import AboutUs from './Components/Other/AboutUs';
import Login from './Components/Forms/Login';
import Product from './Components/Products/Product';
import ProductList from './Components/Products/ProductList';

import { Switch, BrowserRouter, Route } from 'react-router-dom'
import CatalogueDetails from './Components/Forms/CatalogueDetails';
import ContactUs2 from './Components/Forms/ContactUs2';



const Routes = () => {
    return (
        <BrowserRouter> 
            <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/AboutUs"  component={AboutUs}/>
                <Route exact path="/ContactUs"  component={ContactUs}/>
                <Route exact path="/Catalogue"  component={CatalogueDetails}/>
                <Route exact path="/Login"  component={Login}/>
                <Route exact path="/ProductDetails"  component={Product}/>
                <Route exact path="/Products"  component={ProductList}/>
                <Route exact path="/ContactUs2"  component={ContactUs2}/>
            </Switch>
        </BrowserRouter>

    )
}

export default Routes
