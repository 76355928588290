import infutek500H from '../../Assets/Images/Products/infutek500H.png'
import infutekI9 from '../../Assets/Images/Products/infutek_I9.png'
import infutek405 from '../../Assets/Images/Products/infutek405.png'
import infutek405_2 from '../../Assets/Images/Products/stackingOrange.png'
import fetalMonitor from '../../Assets/Images/Products/fetalMonitor.png'
import infutek405ex from '../../Assets/Images/Products/infutek405ex.png'
import infutek405ex_2 from '../../Assets/Images/Products/stackingPurple.png'
import infutek500 from '../../Assets/Images/Products/infutek500.png'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptop, faTabletAlt, faLock, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { GiUltrasound, GiWaterDrop } from 'react-icons/gi';
import { MdBackup } from 'react-icons/md';
import { AiTwotoneUsb } from 'react-icons/ai';
import { BsFillDisplayFill, BsFillLightningFill, } from 'react-icons/bs';
import { FaSyringe } from 'react-icons/fa';
import { RiSensorFill, RiBatteryChargeFill } from 'react-icons/ri';
import { ImSpinner9 } from 'react-icons/im';
import { BiBody, BiCabinet } from 'react-icons/bi';


import i9_3 from '../../Assets/Images/Products/i9_3.png'
import i9_4 from '../../Assets/Images/Products/i9_4.png'

import stacking from '../../Assets/Symbols/stacking.png'
import drugLibrary from '../../Assets/Symbols/drugLibrary.png'
import mostSlick from '../../Assets/Symbols/mostSlick.png'

import ivSelection from '../../Assets/Symbols/ivSet.png'
import bolus from '../../Assets/Symbols/bolus.png'
import dayNight from '../../Assets/Symbols/dayNightMode.png'
import drugLibraryGrey from '../../Assets/Symbols/drugLibraryGrey.png'
import './productDetails.css'

export const productData = [
  {
    productId: 0, 
    name: "Infutek 405", 
    image: infutek405, 
    image2: infutek405_2,
    description: "Simtek-Infutek 405 series syringe driver popularly also known as syringe Infusion Pump is a basic equipment for : Intensive care units (ICU), operating theaters, emergency, anaesthesiology, cardiology, neonatology, paediatrics, oncology and general hospital wards. Ideally suited for administration of anti-cancer drugs, oxytocics, chemotherapy medication, anaesthesia & life saving drugs. Infutek 405 is indispensable wherever high-potency drugs are to be precisely delivered at controlled low rates over an extended period.",
    highlights: [
      { symbol: <BsFillDisplayFill />, feature: "Dual Display" },
      { symbol: <img src={drugLibrary} className="stacking__symbol" alt="Stacking" />, feature: "Drug Library" },
      { symbol: <FontAwesomeIcon icon={faLock} />, feature: "Bolus Time Lock" },
      { symbol: <img src={stacking} className="stacking__symbol" alt="Stacking" />, feature: "Stacking" },
      { symbol: <ImSpinner9 />, feature: "Front Loading" }],

    features:
      ["Convenience of Front Loading",
        "Utility of Stacking",
        "Drug Library",
        "Allows at a Glance Visualisation of",
        "Drug Names, Volume Infused, Infusion",
        "Time & Flow Rate On LCD / LED Displays",
        "Body Weight Rate Calculation",
        "Bolus with Safety Time Lock",
        "Adjustable Target Volume With Prealarm",
        "Programmable to Accept Any Brand of Syringe",
        "Automatic Rate Calculation with Volume and Time Limit",
        "Upgradable to Double Loop PCA (Optional)",
        "Impact Resistant Strong Cabinet to Protect the Equipment During Accidental Mechanical Impact"],


  },

  {
    productId: 1, name: "Infutek 500 NEW", image: infutek500H, image2: infutek500H,
    highlights:
      [
        { symbol: <BsFillDisplayFill />, feature: "Big LED Display" },
        { symbol: <BsFillLightningFill />, feature: "Compact and Easy Operation" },
        { symbol: <RiSensorFill />, feature: "Occlusion sens to L1 to L9" },
        { symbol: <RiBatteryChargeFill />, feature: "10 Hours Battery Backup" },
        { symbol: <img src={ivSelection} className="stacking__symbol" alt="Stacking" />, feature: "Easy Selection of IV Sets" }
      ],
    description: "The Simtek INFUTEK 500 volumetric cum Drop counting Infusion Pump has been designed for high levels of safety & performance with simple operation using latest micro controller based system. The Simtek INFUTEK 500 comprehensive range of features makes it ideal for general, critical care & neonatal infusion where accuracy ",

    features: [
      "BIG BRIGHTNESS LED DISPLAY",
      "COMPACT AND EASY OPERATION",
      "10 HOURS OF BATTERY BACK UP",
      "DOUBLE OCCLUSION SENSITIVITY FOR SAFE INFUSION",
      "9 LEVELS OF VOLUME SETTINGS ",
      "PROGRAMMABLE BOLUS AND  KVO FUNCTION ",
      "ACCEPT ALL KIND OF IV SET  BRAND ",
      "DETACHABLE DRIP SENSOR",],


  },

  {
    productId: 2, name: "INFUTEK 500", image: infutek500, image2: infutek500,
    description: "The Simtek INFUTEK 500 volumetric cum Drop counting Infusion Pump has been designed for high levels of safety & performance with simple operation using latest micro controller based system. The Simtek INFUTEK 500 comprehensive range of features makes it ideal for general, critical care & neonatal infusion where accuracy",
    highlights:
      [{ symbol: <BsFillDisplayFill />, feature: "Large Character LCD" },
      { symbol: <img src={ivSelection} className="stacking__symbol" alt="Stacking" />, feature: "Micro / Adult IV Selection" },
      { symbol: <GiWaterDrop />, feature: "ml mode / dp mode Setting" },
      { symbol: <FontAwesomeIcon icon={faCheckCircle} />, feature: "Compatible with all IV Sets" },
      { symbol: <BiCabinet />, feature: "Moulded Unbreakable Cabinet" }
      ],

    features:
      [" mL/Hr. Setting ",
        "Elapsed time display",
        "KVO mode",
        "Drop / min setting",
        "KVO Mode",
        "Rechargeable battery",
        "Purge facility",
        "Compatible to all IV sets",
        "Free Flow Protection",
        "Target volume setting",
        "Micro infusions",
        "Bolus",
        "Audio-visual alarms",
        "Large character LCD"
      ],

  },

  {
    productId: 3, name: "Infutek I9", image: infutekI9,
    highlights:
      [
        { symbol: <BsFillDisplayFill />, feature: "Graphical Colour TFT" },
        { symbol: <img src={drugLibraryGrey} className="stacking__symbol" alt="Stacking" />, feature: "Drug Library for user" },
        { symbol: <img src={dayNight} className="stacking__symbol" alt="Stacking" />, feature: "Day Night Mode" },
        { symbol: <img src={bolus} className="stacking__symbol" alt="Stacking" />, feature: "Auto / Manual Bolus" },
        { symbol: <img src={mostSlick} className="stacking__symbol" alt="Stacking" />, feature: "Most slick machine in the market" }
      ],
    images: [infutekI9, i9_3, i9_4],
    description: "Simtek‐Infutek i9 series Syringe Infusion Pump is a basic equipment for : Intensive care units (ICU), operating theaters, emergency, anaesthesiology, cardiology, neonatology, paediatrics, oncology and general hospital wards.  Ideally suited for administration of anti‐cancer drugs, oxytocics, chemotherapy medication, anaesthesia & life saving drugs. Infutek i9 is indispensable wherever high‐potency drugs are to be precisely delivered at controlled flow rates over an extended period of time.",
    features:
      ["Compact and light weight design ",
        "Precise occlusion pressure settings",
        "Real time display and more than 80,000 history records",
        "Supports 5, 10, 20, 50(60)ml syringes",
        "Dynamic pressure surveillance (DPS)",
        "Upgradable to PCA (Patient Control Analgesia) mode (optional) ",
        "Day / Night Mode",
        "Drug library with more than 100 nos. of commonly used drugs and can be user defined.",
        "Multiple modes of operation to suit clinical demands of infusion",
        "Longer battery backup",
        "Auto purge / Bolus facility",
        "Slick and uniquely engineered impact Resistant Cabinet",
        "KVO Mode"],


  },


  {
    productId: 4, name: "Mcare K", image: fetalMonitor, image2: fetalMonitor,


    highlights: [
      { symbol: <FontAwesomeIcon icon={faTabletAlt} />, feature: "10.4 inch Graphical TFT Screen" },
      { symbol: <FontAwesomeIcon icon={faLaptop} />, feature: "Structure like a Laptop" },
      { symbol: <GiUltrasound />, feature: "Highly Sensitive Ultrasound Sensors" },
      { symbol: <MdBackup />, feature: "Memory Backup of more than 8000 Hours" },
      { symbol: <AiTwotoneUsb />, feature: "USB for Data Transmission" }
    ],

    description: "The Simtek Mcare K Series high-tech fetal monitor offers a reliable, high-performance solution for fetal monitoring needs in doctors’ practices, clinics and hospitals, from pre-natal check-ups to antepartum monitoring of high-risk pregnancies.",
    features: [
      "Compact And Light Weight Machine",
      "10.4 Inch Folded High –Brightness TFT LED",
      "Memory Back Up More Than 8000 Hrs",
      "Minimum Operating Keys",
      "Audio – Visual Alarms",
      "Highly Sensitive Ultrasound Transducers",
      "USB And Ethernet Facility For Communication",
      "Inbuilt Thermal Printer"],


  },

  {
    productId: 5, name: "Infutek 405 ex", image: infutek405ex, image2: infutek405ex_2,
    highlights: [
      { symbol: <BsFillLightningFill />, feature: "Quick Operation" },
      { symbol: <BiBody />, feature: "Body Weight Mode" },
      { symbol: <FaSyringe />, feature: "PCA" },
      { symbol: <BsFillDisplayFill />, feature: "Blue Display" },
      { symbol: <RiBatteryChargeFill />, feature: "Long Battery" }
    ],
    description: "Simtek-Infutek 405 series syringe driver popularly also known as syringe Infusion Pump is a basic equipment for : Intensive care units (ICU), operating theaters, emergency, anaesthesiology, cardiology, neonatology, paediatrics, oncology and general hospital wards. Ideally suited for administration of anti-cancer drugs, oxytocics, chemotherapy medication, anaesthesia & life saving drugs. Infutek 405 is indispensable wherever high-potency drugs are to be precisely delivered at controlled low rates over an extended period.",
    features: [
      "Convenience of Front Loading",
      "Utility of Stacking",
      "Drug Library",
      "Allows at a Glance Visualisation of",
      "Drug Names, Volume Infused, Infusion",
      "Time & Flow Rate On LCD / LED Displays",
      "Body Weight Rate Calculation",
      "Bolus with Safety Time Lock",
      "Adjustable Target Volume With Prealarm",
      "Programmable to Accept Any Brand of Syringe",
      "Automatic Rate Calculation with Volume and Time Limit",
      "Upgradable to Double Loop PCA (Optional)",
      "Impact Resistant Strong Cabinet to Protect the Equipment During Accidental Mechanical Impact",

    ],


  }
];

