import React from 'react';
import { Nav, Navbar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle,  faPhoneAlt, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import logo from '../../Assets/Images/Logo/navbarLogo.png'

/*<Nav.Link as={Link} to="/" className="nav-link" >  <span className="navLink-text">Life Care... Made Simple</span> </Nav.Link>*/ 

const NavigationBar = () => {
  window.scrollTo(0, 0);
  return (
    <Fade top>
      <Navbar collapseOnSelect expand="lg" variant="light" id="navbar">

        <Navbar.Brand href="#home">
          <center><img src={logo} className="navbarLogo" alt="simtek medico logo" /> </center>

        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="toggler" id="toggler" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto main__links">
            <Nav.Link as={Link} to="/" className="nav-link" > <FontAwesomeIcon className="nav-icon" icon={faHome} />  <span className="navLink-text">  Home</span></Nav.Link>
            <Nav.Link as={Link} to="/AboutUs" className="nav-link" > <FontAwesomeIcon className="nav-icon" icon={faInfoCircle} /> <span className="navLink-text">  About Us</span></Nav.Link>
            <Nav.Link as={Link} to="/Products" className="nav-link" > <FontAwesomeIcon className="nav-icon" icon={faBoxOpen} />  <span className="navLink-text">Products</span></Nav.Link>
            <Nav.Link as={Link} to="/ContactUs" className="nav-link" > <FontAwesomeIcon className="nav-icon" id="contactus" icon={faPhoneAlt} /> <span className="navLink-text"> Contact</span></Nav.Link>
            
          </Nav>
        </Navbar.Collapse>

      </Navbar>
    </Fade>
  )
}

export default NavigationBar
