import React  from 'react'
import NavigationBar from '../Core/Navbar';
import Footer from '../Core/Footer';
import { productData } from './productDetails';
import './Product.css'

import Fade from 'react-reveal/Fade';
import virtualDemoDesktop from '../../Assets/Images/Products/virtualDemoDesktop.jpg'
import virtualDemoMobile from '../../Assets/Images/Products/virtualDemoMobile.jpg'
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';



const Product = () => {
    window.scrollTo(0, 0);
    var id = localStorage.getItem("productID")


    var manageBgColor = (pid) => {
        if (pid === 0) {
            return "product__features__div__0"
        }
        if (pid === 1) {
            return "product__features__div__1"
        }
        if (pid === 2) {
            return "product__features__div__2"
        }
        if (pid === 3) {
            return "product__features__div__3"
        }
        if (pid === 4) {
            return "product__features__div__4"
        }
        if (pid === 5) {
            return "product__features__div__5"
        }


    }



    var manageImage = (pid) => {

        if (pid === 1) {
            return "infutek_500H_image"
        }

        else {
            return ""
        }

    }

    const ManageImageScrolling = () => {
       
        var id = localStorage.getItem("productID")
        
        if ( id === "3") {
            return (
                <Carousel prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" /> }  nextIcon={<span aria-hidden="true" className="carousel-control-prev-icon" /> } indicators={false} className="carousel">
                   
                    {productData[id].images.map((img, index) =>
                        <Carousel.Item key={index}>
                            <img
                                className="i9_carousel_image"
                                src={img}
                                alt="First slide"
                            />
                        </Carousel.Item>


                    )}

                </Carousel>
            )
        }
        else {
            return ( <div> <br/> <center> <img className="product__image__2" src={productData[id].image2} alt="product"/> </center> </div>)
        }
    }
    return (
        <div>
            <NavigationBar />
            <div className="col-xl-12 col-lg-12">

                <div className="row">
                    <Fade bottom>
                        <div className="col-lg-6 col-xl-6 product__image__div" id="product__image__div">
                            <center> <img className="product__image" id={manageImage(id)} src={productData[id].image} alt={productData[id].name} />  </center>
                        </div>
                        <div className="col-lg-6 col-xl-6" id="product__detail__div">
                            <br />
                            <h2 className="product__title" id="product__title">{productData[id].name.toUpperCase()}</h2>
                            <br />
                            <p className="product__description">{productData[id].description}</p>
                            <br /> <br />
                        </div>
                    </Fade>
                </div>

                <br />

            </div>
            <div className="col-xl-12 col-lg-12 test" id={manageBgColor(productData[id].productId)}>
                <center><h1 className="product__features">Highlights </h1> </center>
                <div className="row" >

                    <Fade bottom>
                        {productData[id].highlights.map((data, index) =>
                            <div className="col-xl-4 col-lg-4 col-md-4" key={index}>
                                <br />
                                <center><span className="product__highlight__symbol" >{data.symbol}</span>
                                    <br /><br />
                                    <p className="product__highlight__feature">{data.feature.toUpperCase()}</p></center>

                            </div>

                        )}
                    </Fade>
                </div>
                <br /> <br />
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <h5 className="product__features">Features</h5>
                        <ul>
                            {productData[id].features.map((feature, index) => <li key={index} className="product__feature"> {feature} </li>)}
                        </ul>

                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <ManageImageScrolling/>
                        <br /> <br />
                    </div>
                </div>

            </div>

            <div className="product__catalogue__div">
                <span className="product__feature">
                    <h5 className="product__catalogue__title">Download {productData[id].name.toUpperCase()} Catalogue</h5>

                    <Link to="/Catalogue"> <center> <button className="brochureButton" id="brochureButton">Catalogue</button></center> </Link>
                    <br />
                </span>
            </div>

            <div className="product__virtual__demo">
                <Carousel slide={false} touch={false} interval={null} indicators={false} prevIcon={false} nextIcon={false} >
                    <Carousel.Item>
                        {window.screen.width > 650 ? <img src={virtualDemoDesktop} className="virtualDemo" alt="Syringe and infusion pump meeting" />
                            : <img src={virtualDemoMobile} className="virtualDemo" alt="Syringe and infusion pump meeting" />}
                    </Carousel.Item>
                    <div className="caption__div">
                        <Carousel.Caption>


                            {window.screen.width > 650 ?
                                <div>
                                    <h1 className="virtual__demo__title">Book A Virtual Demo <br /> <br /> With <br /> <br /> Simtek Representative  <br />
                                        <br />
                                        <Link to="/ContactUs"><button className="virtual__demo__button">Book Now</button>    </Link>  </h1> <br />
                                    <br />
                                    <h1 className="caption__div"> </h1>
                                </div>
                                :
                                <div>
                                    <h1 className="virtual__demo__title">Book A Virtual Demo With <br />  Simtek Representative  <br />
                                        <Link to="/ContactUs"><button className="virtual__demo__button">Book Now</button> </Link>   </h1>

                                </div>}

                        </Carousel.Caption>
                    </div>
                </Carousel>
            </div>
            <Footer />
        </div>
    )
}



export default Product
