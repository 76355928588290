import React from 'react'
import Footer from '../Core/Footer'
import NavigationBar from '../Core/Navbar'
import { productData } from './productDetails'
import './ProductList.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';

const ProductList = () => {
    window.scrollTo(0, 0);
    const getProductID = (id) => {
        localStorage.setItem("productID", id)
    }


    return (
        <div>
            <NavigationBar />
            <div className="col-xl-12 col-lg-12 col-md-12 product__list__main__div">
              
                <Fade> <center> <h2 className="product__list__title">Our Products</h2> </center></Fade>
                <br />

                <div className="row" id="product__list__div">

                    <div className="col-xl-6 col-lg-6 col-md-6 product3">
                        <br /> <br />
                        <img className="product__list__image" src={productData[3].image} alt={productData[3].description} />
                        <br />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 product3">
                        <br />  <br /> <br />
                        <span className="product__list__title" id="product__list__title">{productData[3].name.toUpperCase()}</span>
                        <br /> <br />
                        <p className="product__list__description" id="product__list__description">{productData[3].description}</p>
                        <br /> <br />
                        <Link to="/ProductDetails"><button onClick={() => getProductID(productData[3].productId)} className="product__list__button3" id="product__list__button3">Explore Product <span className="button_icon"> <FontAwesomeIcon icon={faArrowRight} /> </span></button> </Link> <br /> <br />
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 product0">
                        <img className="product__list__image" src={productData[0].image} alt={productData[0].description} />
                        <br />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 product0">
                        <br /> <br />
                        <span className="product__list__title">{productData[0].name.toUpperCase()}</span> <br /> <br />
                        <br />
                        <p className="product__list__description">{productData[0].description}</p>
                        <br /> <br />
                        <Link to="/ProductDetails"> <button onClick={() => getProductID(productData[0].productId)} className="product__list__button0" id="button">Explore Product <span className="button_icon"> <FontAwesomeIcon icon={faArrowRight} /> </span></button> </Link>
                        <br /> <br />
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 product5">
                        <img className="product__list__image" src={productData[5].image} alt={productData[5].description} />
                        <br />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 product5">
                        <br />  <br />
                        <span className="product__list__title">{productData[5].name.toUpperCase()}</span>
                        <br /> <br />
                        <p className="product__list__description" >{productData[5].description}</p>
                        <br /> <br />
                        <Link to="/ProductDetails"> <button onClick={() => getProductID(productData[5].productId)} className="product__list__button5" >Explore Product <span className="button_icon"> <FontAwesomeIcon icon={faArrowRight} /> </span></button> </Link>
                        <br /> <br />
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 product4">
                        <img className="product__list__image" src={productData[4].image} alt={productData[4].description} />
                        <br /> 
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 product4">
                        <br /><br /> <br />
                        <span className="product__list__title">{productData[4].name.toUpperCase()}</span>
                        <br /> <br /> <br />
                        <p className="product__list__description" >{productData[4].description}</p>
                        <br /> <br />
                        <Link to="/ProductDetails"><button onClick={() => getProductID(productData[4].productId)} className="product__list__button4" id="button">Explore Product <span className="button_icon"> <FontAwesomeIcon icon={faArrowRight} /> </span> </button> </Link>
                        <br /> <br />
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 product1">
                        <img className="product__list__image__500" src={productData[1].image} alt={productData[1].description} />
                        <br />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 product1">
                        <br /><br /> <br />
                        <span className="product__list__title">{productData[1].name.toUpperCase()}</span>
                        <br /> <br />
                        <p className="product__list__description">{productData[1].description}</p>
                        <br /> <br />
                        <Link to="/ProductDetails"><button onClick={() => getProductID(productData[1].productId)} className="product__list__button1" id="button">Explore Product <span className="button_icon"> <FontAwesomeIcon icon={faArrowRight} /> </span> </button></Link>
                        <br /> <br />
                    </div>



                    <div className="col-xl-6 col-lg-6 col-md-6 product2">
                        <img className="product__list__image__500" src={productData[2].image} alt={productData[2].description} />
                        <br />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 product2">
                        <br /><br />
                        <span className="product__list__title">{productData[2].name.toUpperCase()}</span>
                        <br /> <br />
                        <p className="product__list__description">{productData[1].description}</p>
                        <br /> <br />
                        <Link to="/ProductDetails"> <button onClick={() => getProductID(productData[2].productId)} className="product__list__button1" id="button">Explore Product <span className="button_icon"> <FontAwesomeIcon icon={faArrowRight} /> </span> </button> </Link>
                        <br /> <br />
                    </div>

                </div>

            </div>
            <Footer />
        </div>
    )
}

export default ProductList
