import axios from 'axios';
import React, { useState } from 'react';
import { Fragment } from 'react';
import Footer from '../Core/Footer';
import NavigationBar from '../Core/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './ContactUs.css';
import logo from '../../Assets/Images/Logo/footerLogo.png'
import Fade from 'react-reveal/Fade';
import { Modal, Button } from "react-bootstrap";
import{ init } from 'emailjs-com';
import emailjs from 'emailjs-com';

const ContactUs = () => {

    const mapLocation = "https://www.google.com/maps/embed/v1/place?key=" + process.env.REACT_APP_GOOGLE_MAPS_API + "&q=Simtek+Medico,Goregaon+Mumbai"
    const [value, setValue] = useState({
        isOpen: false
    });

    const { isOpen } = value;

    const closeModal = () => {
        setValue({ isOpen: false })
    }

    const [values, setValues] = useState({

        name: "",
        contactNumber: "",
        email: "",
        location: "",
        message: "",

    });

    const { name, contactNumber, email, location, message } = values;

    const handleChange = name => event => {

        setValues({ ...values, error: false, [name]: event.target.value })
    }
    const onSubmit = event => {

        event.preventDefault();
        setValue({ isOpen: true })
        axios.post(process.env.REACT_APP_CONTACT_US_SPREADSHEET_LINK, {
            name, contactNumber, email, location, message
        })
            .then(response => {
                init(process.env.REACT_APP_USER_ID);
                emailjs.sendForm(process.env.REACT_APP_SERVICE_ID,process.env.REACT_APP_TEMPLATE_ID_CONTACT_US ,event.target, process.env.REACT_APP_USER_ID)
                .then((result) => {
                   
                }, (error) => {
                    setValue({isOpen: false })
                });
                setValue({ isOpen: true });
                setValues({
                    name: "",
                    contactNumber: "",
                    email: "",
                    location: "",
                    message: "",
                })
            })
            .catch(setValue({ isOpen: false }), setValues({ ...values, err: true }))
    }

    return (
        <Fragment>

            <NavigationBar />
            <Modal show={isOpen} onHide={!isOpen} className="modal__description">
                <Modal.Header closeButton>
                    <Modal.Title>Thank You User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-background-color">
                        <span className="modal__description">Our Representative will contact you soon</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="success" onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <div className="row" >

                <div className="col-md-7 col-lg-7 col-xl-7 text-left">

                    <Fade top>
                        <br /><br />
                        <iframe className="map"
                            width="600"
                            height="450"
                            frameBorder="0"
                            title="map"
                            src={mapLocation} allowFullScreen>
                        </iframe>
                        <br /> <br />
                        <center>
                            <span className="logo"><img src={logo} className="footerLogo" alt="simtek medico systems. we provide the best syringe pump" /></span> </center>

                        <br />
                        <h5 className="contact__address">
                            <FontAwesomeIcon icon={faMapMarkerAlt} /> : Simtek Medico Systems Pvt. Ltd. 110, Bhola Bhagwan Ind Estate, I B Patel Road, Goregaon (E), Mumbai- 400063. INDIA
                        </h5>

                        <br />
                        
                        <h5 className="telephone" >
                            <FontAwesomeIcon icon={faPhoneAlt} /> :
                            
                            <a href="tel:‎+912249733568" className="telephone__number">+91 22 4973 3568</a> /
                            <a href="tel:‎+912242642465" className="telephone__number">‎+91 22 4264 2465</a>
                        
                        </h5>
                        <br />
                        <h5 className="telephone" >
                             <FontAwesomeIcon icon={faPhoneAlt} /> : 
                            
                            <a href="tel:‎+919821340982" className="telephone__number">‎+91 9821340982 </a> /
                            <a href="tel:‎+917977099618" className="telephone__number">‎+91 7977099618 </a> /
                            <a href="tel:‎+919930740982" className="telephone__number">‎+91 9930740982 </a>
                        </h5>
                        <br />
                        <h5 className="mail">
                            <FontAwesomeIcon icon={faEnvelope} /> :
                            <a href="mailto:simtekm@yahoo.co.in" className="telephone__number"> simtekm@yahoo.co.in </a> /
                            <a href="mailto:simtekmedicosystem@gmail.com" className="telephone__number"> simtekmedicosystem@gmail.com </a>

                        </h5>

                        <br />

                        <h5 className="contact__socialMedia" >Follow us :
                            <a href="https://www.instagram.com/simtek_medico/" className="contact__socialMediaLinks"><FontAwesomeIcon icon={faInstagram} /> </a>
                            <a href="https://www.facebook.com/simtekmedicosystems/" className="contact__socialMediaLinks"><FontAwesomeIcon icon={faFacebook} /> </a>
                            <a href="https://twitter.com/simtekm" className="contact__socialMediaLinks"><FontAwesomeIcon icon={faTwitter} /> </a>
                            <a href="https://www.youtube.com/channel/UCKozNYrwQOEWRmbXj_vse1A" className="contact__socialMediaLinks"><FontAwesomeIcon icon={faYoutube} /> </a>

                        </h5>
                        <br />
                    </Fade>
                </div>


                <div className="col-md-4 col-lg-4 col-xl-4 text-left" id="contactUs">
                    <Fade>
                        <h3 className="contact_title">Contact Us</h3>

                        <form onSubmit={onSubmit} >

                            <div className="form-group required" id="label">
                                <label className="text-dark">Name </label>
                                <input required value={name} onChange={handleChange("name")} className="form-control required" type="text"  />
                            </div>
                            <div className="form-group" id="label">
                                <label className="text-dark">Contact Number</label>
                                <input  required value={contactNumber} onChange={handleChange("contactNumber")} className="form-control required" type="text"  />
                            </div>
                            <div className="form-group" id="label">
                                <label className="text-dark">Email </label>
                                <input required value={email} onChange={handleChange("email")} className="form-control required" type="text" />
                            </div>
                            <div className="form-group" id="label">
                                <label className="text-dark"> Location </label>
                                <input required value={location} onChange={handleChange("location")} className="form-control required" type="text"  />
                            </div>
                            <div className="form-group" id="label">
                                <label className="text-dark"> Message </label>
                                <input required value={message} onChange={handleChange("message")} className="form-control required" type="text"/>
                            </div>

                            <div className="form-group" id="label">

                                <input type="submit" className="btn btn-dark btn-block" id="userSignUpButton" value="Submit"></input>
                                <br />

                            </div>
                            


                        </form>
                    </Fade>
                </div>


            </div>
            <Footer />
        </Fragment>
    )
}

export default ContactUs
