import React, { lazy, Suspense } from "react";
import NavigationBar from "./Navbar";
import img1 from "../../Assets/Images/HomePage/01.jpg";
import img2 from "../../Assets/Images/HomePage/02.png";
import img3 from "../../Assets/Images/HomePage/03.jpg";
import img4 from "../../Assets/Images/HomePage/04.jpg";
import img5 from "../../Assets/Images/HomePage/05.jpg";
import { Carousel } from 'react-bootstrap'
import "./HomePage.css";
import Fade from 'react-reveal/Fade';

const ProductCarousel = lazy(() => import("./ProductCarousel"));
const Footer = lazy(() => import("./Footer"));

const HomePage = () => {
    

    const images = [{ img: img1 }, { img: img2 }, { img: img3 }, { img: img4 }, { img: img5 }]
    return (
        <div>
            <NavigationBar />
            <Carousel  indicators={false} prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" /> }  nextIcon={<span aria-hidden="true" className="carousel-control-prev-icon" /> }>
                {images.map((image, index) =>
                    
                        <Carousel.Item key={index} >
                        <Fade>
                        <img
                            className="carousel__image__main"
                            src={image.img}
                            alt="First slide"
                        />
                        </Fade>
                    </Carousel.Item> 
                )}
            </Carousel>
            <Suspense fallback={<div>loading ..</div>}>
                <ProductCarousel />
                <Footer />
            </Suspense>
        </div>
    );
};

export default HomePage;
