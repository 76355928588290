import React from 'react';
import Footer from '../Core/Footer';
import NavigationBar from '../Core/Navbar';
import './Login.css'
import Fade from 'react-reveal/Fade';


const Login = () => {
    window.scrollTo(0, 0);
    return (
        <div className="">
            <NavigationBar/>
            <div className="col-xl-4 offset-xl-4">
                <br/><br/>
                <Fade> <center> <h1 className="loginTitle">Login</h1> </center> </Fade>
            <div className = "form-group" id="label">
            <Fade>  <label className = "text-dark">Username </label>
                <input className="form-control" type="text"></input> </Fade>
            </div>

            <div className = "form-group" id="label">
            <Fade > <label className = "text-dark">Password </label>
                <input  className="form-control" type="password"></input> </Fade>
            </div>
            
            <div className="form-group" > 
            <Fade>  <button className="btn btn-dark btn-block" id="userSignUpButton">Login</button> </Fade>
                <br/>           
            </div>

            </div>
            <Footer/>
        </div>
    )
}

export default Login
